.cluster-definition .no-cluster {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 24px;
	gap: 24px;
}
.cluster-definition .no-cluster .title {
	font-size: 20px;
	font-weight: 600;
}
.cluster-definition .no-cluster .warning {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	padding: 12px;

	background-color: rgba(253, 200, 47, 0.4);
}
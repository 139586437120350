.team-container .team-content .cards-container .card.p-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    gap: 20px;
    width: 305px;
    min-width: 305px;
    background: #fff !important;
}

.team-container .team-content .cards-container .card div img {
    width: 100%;
}

.team-container .team-content .cards-container .card.p-card .card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.team-container .team-content .cards-container .card .card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    align-items: center;
    padding: 20px;
}

.team-container .team-content .cards-container .card .card-info h2{
    font-weight: 400;
}

.team-container .team-content .cards-container .card .card-info p {
    color: #000;
}

.team-container .team-content .cards-container .card .card-info h2 strong {
    font-weight: bold;
}

.team-container .team-content .cards-container .card .card-info .icons-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    gap: 10px;
}

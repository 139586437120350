.dialog-content h2{
    color: #FDC82F;
}

.dialog-content .what-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    width: 100%;
    margin-top: 10px
}

.dialog-content .what-cards .p-card-content.tool-what-card{
    background: #F7F7F7;
    padding: 20px;
}

/*.dialog-content .what-cards .p-card-content .p-button{*/
/*    margin: 0 12px 12px;*/
/*}*/



.rotate-ylabel-cimate{
    transform: rotate(-90deg);
    position: relative;
    z-index: 1;
    bottom: 54%;
    right: 49%;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.rotate-xlabel-cimate{
    text-align: center;
    position: relative;
    z-index: 1;
    top: 52%;
    font-size: 14px !important;
    font-weight: 400 !important;
}
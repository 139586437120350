.popup-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 5px;
    height: 0;
}

.popup-container .popup-content {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    background: #fff;
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 10px 20px;
    gap: 10px;
    border-radius: 4px;
}

.popup-container .popup-content .popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.popup-container .popup-content .popup-header h2{
    color: #FDC82F;
    font-size: 29px;
    font-family: Montserrat;
}

.popup-container .popup-content .popup-header i{
    font-size: 18px;
}

.popup-container .popup-content .popup-header i:hover{
    cursor: pointer;
}

.popup-container .popup-content p{
    font-size: 18px;
    font-weight: 500;
}


.popup-container .popup-content .warning {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;
    padding: 12px;

    background-color: rgba(253, 200, 47, 0.4);
}

.popup-container .popup-content .warning p{
    font-weight: 400;
    font-size: 16px;
}

.popup-container .popup-content  .registration-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.popup-container .popup-content  .registration-form .inner-content, .popup-container .popup-content .registration-form .email-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.popup-container .popup-content .registration-form .inner-content p, .popup-container .popup-content .registration-form .email-content p{
    font-size: 16px;
}

.popup-container .popup-content  .registration-form .agreement-check{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.popup-container .popup-content .popup-buttons{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

/*.popup-container .popup-content .black{*/
/*    border: 1px solid #000;*/
/*}*/

.popup-container .popup-content .invalid{
    border: 2px solid #ff3030;
}

.popup-container .popup-content .invalid:hover{
    border: 2px solid #ff3030;
}

.popup-container .popup-content .inputs{
    border: 2px solid #000 !important;
}

.p-dialog .p-dialog-header{
    padding: 0 !important;
}
.information-page .expanded-menu .tab .filters .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.information-page .expanded-menu .tab .filters .slider .slider-title {
    font-weight: 600;
}

.information-page .expanded-menu .tab .filters .p-slider {
    width: 100%;
    background-color: transparent;
}

.information-page .expanded-menu .tab .filters .slider .labels {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.information-page .expanded-menu .tab .filters .slider .slider-container {
    display: flex;
    width: 100%;
    padding: 8px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
.information-page .expanded-menu .tab .filters .slider .slider-container .p-slider-range {
    display: none;
}
.information-page .expanded-menu .tab .filters .slider .slider-container .p-slider-handle {
    height: 30px;
    width: 30px;
    margin-top: -15px;
    margin-left: -15px;
}
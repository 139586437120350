.app .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 30px;

    padding: 19px;
    background-color: #2d2d2d;
}
.app .footer .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 36px;
}
.app .footer .logos img {
    height: 60px;
}
.app .footer .privacy-terms {
    display: flex;
    flex-direction: row;
    gap: 12px;
    /*margin-right: 2%;*/
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}

.app .footer .privacy-terms p {
    color: #fff;
    cursor: pointer;
}

.app .footer .powered-by p{
    color: #fff;
    padding-top: 10px;
    text-align: right;
}

.app .footer .social {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.app .footer a {
    display: flex;
}

.footer-acknowledgements a {
    font-weight: 600;
}

.footer-acknowledgements a:active {
    color: #FDC82F;
}

.privacy-terms .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    font-family: "Open Sans";
}

.privacy-terms .title {
    font-size: 20px;
    font-weight: 600;
    font-family: Montserrat;
}
.privacy-terms .sub-title {
    font-size: 18px;
    font-weight: 600;
    font-family: Montserrat;
}
.privacy-terms p {
    font-size: 16px;
    font-family: "Open Sans";
}


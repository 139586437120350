.information-page .body-container .where {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.information-page .body-container .where .warning {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	padding: 12px;

	background-color: rgba(253, 200, 47, 0.4);
}
.zoom-buttons {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 20%;
	right: 24px;
	z-index: 1;
	gap: 4px;
}

.zoom-buttons .zoom {
	display: flex;
	padding: 8px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	border: 1px solid #a8a8a8;
	border-radius: 4px;
	background-color: white;
	transition: all	0.3s ease;
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)
}
.zoom-buttons .zoom:hover {
	background-color: rgb(245, 245, 245);
}

.zoom-buttons .zoom i {
	color: #606060;
}

.na-chart {
	position: relative;
}
.dialog-content .tool .sub-page .navigation {
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
}

.dialog-content .tool .sub-page .navigation .stepper {
	display: flex;
	flex-direction: row;
	gap: 40px;
}
.dialog-content .tool .sub-page .navigation .stepper .step-circle{
	width: 40px;
	height: 40px;
	border-radius: 40px;
	cursor: pointer;
}

.dialog-content .tool .sub-page .navigation .navigation-buttons {
	display: flex;
	flex-direction: row;
	gap: 40px;
}
.modeling{
    display: flex;
    flex-direction: row;
    gap: 50px;
    text-align: justify;
}

.modeling .general,
.modeling .livestock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.modeling .general .header,
.modeling .livestock .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    gap: 10px;
}

.modeling .general .header img,
.modeling .livestock .header img {
    width: 40px;
}

.iframe-container{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

}
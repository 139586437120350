.dialog-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.dialog-content .learn-more-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.submit-button{
    display: flex;
    justify-content: center;
}

.dialog-content .p-column-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-content .p-column-title {
    color: #427730;
    font-weight: bold;
    font-size: 16px;
}

.dialog-content .p-rowgroup-header td{
    border-bottom: 1px solid #427730 !important;;
}

.dialog-content .row-group-header {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    color: #427730;
}

.dialog-content .row-group-header .category {
    color: #427730;
    font-weight: bold;
    font-size: 16px;
}

.dialog-content .p-datatable {
    width: 100%;
}

.dialog-content .p-datatable-tbody td {
    width: 0%;
}
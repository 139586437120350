.dialog-content .checkbox {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	width: 35px;
	border: 1px solid black;
	cursor: pointer;
	box-shadow: 0 0 4px 0px black;
	transition: all 0.3s ease;
}

.dialog-content .checkbox p {

}
.information-page .expanded-menu .tab .filters {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 24px;
}

.information-page .expanded-menu .tab .filters .during {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.information-page .expanded-menu .tab .dropdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 8px;
	font-weight: 600;
}

.information-page .expanded-menu .tab .multiple p {
	width: 250px !important;
	text-align: left;
}

.information-page .expanded-menu .tab .during .p-dropdown, .information-page .expanded-menu .tab .during .p-multiselect {
	width: 120px !important;
}
.leaflet-marker-icon.myIcon {
    font-size: 40px;
    color: #2693c9;
}

.map-component .map-legend{
    margin-bottom: 20px;
    z-index: 1001;
    position: absolute;
    bottom: 12px;
    right: 12px;
    background: #fff;
    border-radius: 4px;
}

.map-component .map-legend p{
    margin-bottom: 15px;
    font-weight: normal;
}

.map-component .map-legend h2{
    color: #FDC82F;
    margin: 20px 0 0 20px;
}

.map-component .map-legend div i{
    margin: 20px 20px 0 20px;
}

.map-component .map-legend .square-presentation{
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 10px;
}

.map-component .map-legend .square-presentation .square{
    width: 20px;
    height: 20px;
    border: 1px solid #000;
}

.map-component .map-legend .color-pallete-wrap{
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 10px;
    align-items: center;
}

.map-component .map-legend .color-pallete-wrap div .legends{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    font-size: 14px;
}

.map-component .map-legend .color-pallete-wrap div .legends p{
    margin-right: 15px;
    margin-left: 15px;
}

.map-component .map-legend .color-pallete-wrap .heading-legend{
    font-size: 14px;
    font-weight: bold;
}

.map-component .map-legend .color-pallete-wrap div .legends p.other{
    margin-right: 15px;
    margin-left: 10px;
}

.map-legend .color-pallete-wrap .square-size {
    width: 20px;
    height: 20px;
}

.map-component .map-legend .download-button {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.map-component .open-legend-btn{
    margin-bottom: 20px;
    z-index: 1001;
    position: absolute;
    bottom: 12px;
    right: 12px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    /*height: 32px;*/
    padding: 8px;
}

.map-component .open-legend-btn i {
    font-size: 25px;
}

.custom-cluster-icon {
    background-color: rgba(110, 204, 57, 0.54);
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% ;
    /*margin-left: -20px;*/
    /*margin-top: -20px;*/
    /*width: 40px;*/
    /*height: 40px;*/
    /*transform: translate3d(901px, 374px, 0px);*/
    /*z-index: 374;*/
}

.cluster-icon-content { /* Set text color */
    font-size: 13px; /* Adjust font size */
    text-align: center; /* Center-align text */
    line-height: 30px; /* Adjust line height for vertical centering */
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    border-radius: 15px;
    background-color: rgba(110, 204, 57, 0.73);
}

.livestock-dialog .p-column-title {
    color: #427730;
    font-weight: bold;
    font-size: 16px;
}
.livestock-dialog .chart-container {
    position: relative;
}
.livestock-dialog .chart-container .rotate-label {
    right: -100px;
    position: absolute;
}
.country-profiles-page {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.66), rgba(0, 0, 0, 0.63)), url("../../assets/images/CountryProfiles/countryProfiles.jpg");
    background-position: 5% 95%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
    padding: 0 30px;
}

.country-profiles-page .side-icons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*padding-left: 20px;*/
    gap: 60px;
    width: 70%;
}

.country-profiles-page .dropdown-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 70px;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

.dialog-tool-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}

.dialog-tool-container h3{
    font-family: Montserrat;
    font-size: 36px !important;
    font-weight: bold !important;
    color: #FDC82F;
}

.dialog-tool-container .hyperlink-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dialog-tool-container a i {
    font-size: 20px;
}

.dialog-img-container{
    display: flex;
    justifyContent: center;
}
.dialog-img-container img {
    width: 100%;
    /*height: 100%;*/
    object-fit: cover;
}
.open-tool-button{
    color: #FDC82FFF;
    background: #2D2D2D;
    border: 0 none;
    padding: 0.714rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
    margin-bottom: 40px;
    width: fit-content;
}

.dialog-info-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dialog-info-container p{
    line-height: 22px;
}

.open-tool-button:hover{
    background: #FDC82FFF;
    color: #2D2D2D;
    cursor: pointer;
}

.home .expanded-menu .twitter-card{
    width: 100%;
    display: flex;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

/* media queries */
.dialog{
    width: 80%;
    height: 90%;
}

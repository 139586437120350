.dialog-content .tool {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 30px;
}

.dialog-content .tool .title {
	font-size: 36px;
	font-weight: 600;
	color: #FDC82F;
}

.dialog-content .tool .sub-page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	padding: 20px;
	background-color: #F8F8F8;
	height: 100%;
}
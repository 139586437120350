.learn-more-content .data-sources .p-datatable-responsive-scroll>.p-datatable-wrapper>table {
    table-layout: auto !important;
}
.learn-more.p-dialog .learn-more-content .p-datatable-tbody td {
    width: unset !important ;
}

.p-dialog .p-dialog-header {
    background-color: #f7f7f7 !important;
}

.title{
    text-transform: capitalize;
}
.information-page .body-container .who {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.megapixel-details-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 24px;
}

.megapixel-details-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}

.megapixel-details-content .indicator-template {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	position: relative;
}

.megapixel-details-content .indicator-template i {
	cursor: pointer;
}

/*.megapixel-details .row-group-header {*/
/*	display: flex;*/
/*	width: 100%;*/
/*	justify-content: center;*/
/*	color: #427730;*/
/*	font-weight: bold;*/
/*	font-size: 16px;*/
/*}*/

.megapixel-details .p-datatable, .megapixel-details .p-datatable-table,.megapixel-details .p-datatable, .megapixel-details .p-datatable-tbody, .megapixel-details tr {
	width: 100%;
}

.megapixel-details .p-datatable-tbody td {
	width: 0%;
	overflow-wrap: break-word;
}

.megapixel-details .p-column-title {
	color: #427730;
	font-weight: bold;
	font-size: 16px;
}

.megapixel-details .indicators {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 24px;
}

.megapixel-details .indicators .indicator {
	display: flex;
	padding: 8px;
	border-radius: 20px;
	background-color: #285E69;
	color: white;
}

.megapixel-details .sub-title {
	width: fit-content;
	text-align: center;
	border-bottom: 1px solid #2D2D2D;
	font-size: 20px;
	padding: 4px 0;
	font-weight: 500;
}
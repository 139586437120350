.information-page .body-container .context .subpage {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 24px;
	gap: 24px;
}

.information-page .body-container .context .subpage .header {
	display: flex;
	flex-direction: column;
	gap: 24px;
}


.information-page .body-container .context .subpage .title {
	font-size: 32px;
	font-weight: 600;
}

.information-page .body-container .context .subpage .header .sub-title {
	font-size: 24px;
	font-weight: 600;
}

.information-page .body-container .context .subpage .header .text {
	line-height: 145%;
	width: 60%;
}
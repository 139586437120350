.information-page .body-container .who .map-component {
	width: 100%;
	height: 100vh;
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: column;
}

.information-page .body-container .who .map-component .leaflet-container {
	width: 100%;
	height: 100%;
}

.information-page .body-container .who .map-component .reset-zoom {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 1001;
	cursor: pointer;
	background: white;
	height: 32px;
	width: 32px;
	border-radius: 4px;
}

.information-page .body-container .who .map-component .reset-zoom i {
	font-size: 16px;
}

.information-page .body-container .who .map-component .map-legend{
	margin-bottom: 20px;
	z-index: 1001;
	position: absolute;
	bottom: 12px;
	right: 12px;
	background: #fff;
	border-radius: 4px;
}

.information-page .body-container .who .map-component .map-legend h2{
	color: #FDC82F;
	margin: 20px 0 0 20px;
}

.information-page .body-container .who .map-component .map-legend div i{
	margin: 20px 20px 0 20px;
}

.information-page .body-container .who .map-component .map-legend .square-presentation{
	display: flex;
	flex-direction: row;
	padding: 20px;
	gap: 10px;
}

.information-page .body-container .who .map-component .map-legend .square-presentation .square{
	width: 20px;
	height: 20px;
	border: 1px solid #000;
}

.information-page .body-container .who .map-component .map-legend .color-pallete-wrap{
	display: flex;
	flex-direction: row;
	padding: 20px;
	gap: 10px;
}

.information-page .body-container .who .map-component .map-legend .color-pallete-wrap .square-size {
	width: 20px;
	height: 20px;
}

.information-page .body-container .who .map-component .map-legend .download-button {
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
}

.information-page .body-container .who .map-component .open-legend-btn{
	margin-bottom: 20px;
	z-index: 1001;
	position: absolute;
	bottom: 12px;
	right: 12px;
	background: #fff;
	border-radius: 4px;
	cursor: pointer;
	/*height: 32px;*/
	padding: 8px;
}

.information-page .body-container .who .map-component .open-legend-btn i {
	font-size: 25px;
}

.information-page .body-container .who .warning {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	padding: 12px;

	background-color: rgba(253, 200, 47, 0.4);
}
.information-page .expanded-menu .tab .options {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
}

.information-page .expanded-menu .tab .options .p-button {
	border-radius: 20px;
}

.information-page .expanded-menu .tab .dropdown {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 8px;
}

.information-page .expanded-menu .tab .dropdown p {
	width: 200px;
	text-align: left;
}

.information-page .expanded-menu .tab .dropdown .p-dropdown, .information-page .expanded-menu .tab .dropdown .p-multiselect {
	width: 200px;
}
/*bottom: 140px;*/
/*width: 100%;*/
/*height: 100vh;*/
/*position: absolute;*/
/*top: 58%;*/
/*z-index: -1;*/
/*overflow: hidden;*/
.globe {
    width: 100%;
    height: 250vh;
    position: absolute;
    top: 30%;
    z-index:-1;

}
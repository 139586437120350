.information-page .body-container .what {
    display: flex;
    padding: 40px 16px;
    width: 100%;
    gap: 100px;
    align-items: stretch;
    /*height: 100%;*/
}

.information-page .body-container .what .what-content{
    display: flex;
    flex-direction: column;
    /*padding-right: 40px;*/
    width: 100%;
    height: 100%;
    gap: 30px;
    /*position: absolute;*/
    /*top: 20px;*/
}

.information-page .body-container .what .what-content .go-back-btn{
    position: relative;
    top: 20px;
    margin-bottom: 30px;
}

.country-page-dialog .dialog-content {
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}

.country-page-dialog .dialog-content h3{
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    color: #FDC82F;
}

.country-page-dialog .dialog-content .text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.country-page-dialog .dialog-content .p-tree {
    max-height: 400px;
    width: 300px;
    overflow-y: scroll;
}

.country-page-dialog .dialog-content .p-tree .p-tree-container .p-treenode .p-tree-toggler {
    display: none;
}

.country-page-dialog .dialog-content ::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.country-page-dialog .dialog-content ::-webkit-scrollbar-track {
    background: rgba(45, 45, 45, 0.74);
}

/* Handle */
.country-page-dialog .dialog-content ::-webkit-scrollbar-thumb {
    background: #FDC82F;
}

.dialog-content .accordion-steps{
    margin-top: 40px;
}

.dialog-content .accordion-steps .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #4A4A4A;
    color: #FDC82F;
}

.dialog-content  .accordion-steps .p-accordion .p-accordion-header .p-accordion-header-link{
    background: #4A4A4A;
    color: #FDC82F;
}

.dialog-content  .accordion-steps  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
    background: #4A4A4A;
    color: #FDC82F;
}

.dialog-content  .accordion-steps  .p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header-link:focus{
    background: #4A4A4A;
    color: #FDC82F;
}

.dialog-content  .accordion-steps .p-accordion .p-accordion-tab:first-child{
    margin-bottom: 20px;
}

.dialog-content  .accordion-steps .first-step-dropdowns{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.dialog-content  .accordion-steps .first-step-dropdowns .dropdown-item{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 15%;
}
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.74), rgba(0, 0, 0, 0.74)), url("../../assets/images/Home/home.jpg");
    background-position: 5% 85%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
    padding: 100px 50px;
}

.home h1{
    color: #fff;
    font-size: 64px;
    font-family: Montserrat;
    text-transform: capitalize;
}

.home .home-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    color: #fff;
    gap: 50px;
    padding: 150px 50px;
}

.home .home-content h2{
    font-family: Montserrat;
    font-size: 30px;
}

.home .home-content .country-profiles{
    width: 60%;
}

.home .home-content .country-profiles, .climate-security-tools{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.home .home-content .country-profiles a, .climate-security-tools a{
    color:#FDC82F
}

.home .home-content .explore-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color:#FDC82F;
    cursor: pointer;
}

.climate-security-text::after{
    content: ".";
}

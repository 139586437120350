.information-page .body-container .how {
    display: flex;
    padding: 40px;
    width: 100%;
    gap: 100px;
    align-items: stretch;
    justify-content: center;
}
.information-page .body-container .how-cards {
    display: flex;
    width: 100%;
    gap: 40px;
    align-items: stretch;
}

.information-page .body-container .how .how-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.information-page .body-container .how .how-info h2{
    font-size: 32px;
}

/*.information-page .body-container .how .how-info .summary-img{*/
/*    text-align: center;*/
/*}*/

/*.information-page .body-container .how .how-info .summary-img .p-image-preview-indicator {*/
/*    transition: none;*/
/*}*/

/*.information-page .body-container .how .how-info .summary-img .p-image-preview-indicator:hover {*/
/*    background: transparent !important;*/
/*    color: transparent !important;*/
/*}*/

.information-page .body-container .how .how-content{
    display: flex;
    flex-direction: column;
    /*padding-right: 40px;*/
    width: 100%;
    gap: 30px;
    /*position: absolute;*/
    /*top: 20px;*/
}


.information-page .body-container .how .how-content .go-back-btn{
    position: relative;
    top: 20px;
    margin-bottom: 30px;
}
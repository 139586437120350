.livestock-container{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.livestock-container .environmental-layers{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.livestock-container .slider{
    display: flex;
    flex-direction: column;
}

.livestock-container .slider p {
    font-weight: 600;
    margin-bottom: 20px;
}

.livestock-container .disable {
   display: none;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox{
    display: none !important;
}
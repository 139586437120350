.bg-image-platform {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-image: url("../../../../assets/images/Platform/platform.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 160px);
    position: relative;

    overflow: hidden;
    -webkit-animation: imgFade 5s;
    animation: imgFade 5s;

}

@-webkit-keyframes imgFade
{
    0% {
        background: rgba(0, 0, 0, 0.7);
    }
    100% {
        background: rgba(0, 0, 0, 0.7);
    }
}

.bg-image-platform .p-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;


    gap: 40px;
    z-index: 100;

    min-height: calc(100vh - 160px);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.61),
                    rgba(0, 0, 0, 0.61)
            )
}

.bg-image-platform .p-carousel-content {
    gap: 40px;
}

.bg-image-platform .p-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.bg-image-platform .carousel-container {
    /*padding: 0 450px;*/
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    gap: 12px;
}

.bg-image-platform .carousel-container h2 {
    width: 100%;
    color: #fff;
    padding-bottom: 40px;
}

.bg-image-platform .carousel-container .carousel-text {
    width: 80%;
    color: #fff;
}

.bg-image-platform .carousel-container .carousel-list {
    width: 75%;
    color: #fff;
    margin: 0;
    padding: 0;
}

/* platform class styles*/

.platform-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100vh - 160px);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
            );
    overflow: hidden;
}
.platform-container iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 178vh;
    position: absolute;
    top: 0;
    left: 0;
}

.platform-container .p-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;


    gap: 40px;
    z-index: 100;

    min-height: calc(100vh - 160px);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.61),
                    rgba(0, 0, 0, 0.61)
            )
}
.platform-container .p-carousel-content {
    gap: 40px;
}

.platform-container .p-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.platform-container .carousel-container {
    /*padding: 0 450px;*/
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    gap: 12px;
}

.platform-container .carousel-container h2 {
    width: 100%;
    color: #fff;
    padding-bottom: 40px;
}

.platform-container .carousel-container .carousel-text {
    width: 80%;
    color: #fff;
}

.platform-container .carousel-container .carousel-list {
    width: 75%;
    color: #fff;
    margin: 0;
    padding: 0;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    display: none;
}

.p-carousel .p-carousel-indicators {
    gap: 30px;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button{
    background: #ffffff !important;
    height: 25px !important;
    width: 25px;
    width: 25px !important;
    border-radius: 25px !important;
    min-width: 25px !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background: #fdc82f !important;
    color: #ffffff !important;
    height: 25px;
    width: 25px !important;
    border-radius: 25px !important;
    min-width: 25px;
}


.na-graph {
	display: flex;
	flex-direction: column;
}

.na-graph .p-multiselect {
	width: 10vw;
}

.na-graph .text-dropdown-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 70px;
}
.na-graph .text-dropdown-container .p-button {
	height: unset;
}

.na-graph .p-panel-content {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
}

.na-graph .p-panel {
	margin: 0;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 25px;
}
/* flip cards */

.flip-card {
	display: flex;
	align-items: center;
	background-color: transparent;
	width: 155px;
	height: 155px;

	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;

	cursor: pointer;
}

.flip-card span {
	color: #FDC82F;
	font-weight: 600;
}

.flip-card p {
	color: white;
}

.flip-card  .image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	background-color: transparent;
}

.flip-card  .image-container svg {
	opacity: 1;
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.flip-card .question-rectangle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 12px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	opacity: 0;
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.flip-card  .image-container:hover svg, .flip-card  .image-container.active svg {
	opacity: 0;
}

.flip-card  .image-container:hover .question-rectangle, .flip-card  .image-container.active .question-rectangle  {
	opacity: 1;
}
.summary-img{
    text-align: center;
}

.coming-soon-heading{
    font-size: 30px;
    color: #FDC82F;
}

.summary-img .p-carousel .p-carousel-content .p-carousel-prev, .summary-img  .p-carousel .p-carousel-content .p-carousel-next {
    display: flex;
    background-color: #2d2d2d !important;
    color: #FDC82F !important;
}

.summary-img .p-carousel .p-carousel-indicators .p-carousel-indicator {
    display: none;
}

.summary-img .p-carousel .p-carousel-container img {
    width: 90%;
}
.summary-diagram .p-button {
    width: fit-content;
}
.resources-dialog-content .p-column-title {
	color: #427730;
	font-weight: bold;
	font-size: 16px;
}

.resources-dialog-content .p-rowgroup-header td{
	border-bottom: 1px solid #427730;
}

.resources-dialog-content .row-group-header {
	display: flex;
	width: 100%;
	justify-content: center;
	color: #427730;
}

.resources-dialog-content .row-group-header .category {
	color: #427730;
	font-weight: bold;
	font-size: 16px;
}

.resources-dialog-content .p-datatable {
	width: 100%;
}

.resources-dialog-content .p-datatable-tbody td {
	width: 0%;
}
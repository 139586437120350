.dialog-content .tool .sub-page .step .dropdown-item {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 40%;
}

.dialog-content .tool .sub-page .step .dropdown-item p {
	font-weight: 600;
}

.disable-text {
	color: #a1a0a0;
}

.enable-text{
	color: #000;
}
.dialog-content .results {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}

.dialog-content .results .sub-title {
	width: 100%;
	font-size: 20px;
	font-weight: 500;
}

.dialog-content .content {
	display: flex;
	flex-direction: row;
}

.dialog-content .content .information {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: 40%;
	background-color: #F7F7F7;
}

.dialog-content .content .information .sub-title {
	font-size: 16px;
	font-weight: 500;
}

.dialog-content .content .charts {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 60%;
	z-index: 2;
	overflow: hidden;
}

.dialog-content .content .charts .buttons {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-evenly;
}

.dialog-content .content .invisible-charts{
	position: absolute;
	z-index: -1;
	display: flex;
	flex-direction: row;
	visibility: hidden;
	width: 60%;
}

.dialog-content .content .charts .buttons .p-button {
	border-radius: 20px;
}

.csst-download-dialog {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
	padding: 24px;
	align-items: center;
	justify-content: center;
}

.csst-download-dialog .warning {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	padding: 12px;

	background-color: rgba(253, 200, 47, 0.4);
}

.csst-download-dialog .checkbox-container {
	display: flex;
	flex-direction: row;
	gap: 24px;
}

.csst-download-dialog .fields {
	display: flex;
	flex-direction: column;
	gap: 6px;
}
.csst-download-dialog .fields p {
	font-weight: 600;
	font-size: 16px;
}
.toolkit-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.toolkit-container h3{
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 500;
}

.toolkit-container .accordions{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    /*height: 500px;*/
    width: 100%;
    align-items: flex-start;
}

.toolkit-container .accordions .text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 20px;
}

.toolkit-container .accordions .p-accordion {
    width: 100%;
}

/*.voices-field-content .map-and-info .p-accordion-content {*/
/*	height: 350px;*/
/*	overflow-y: scroll;*/
/*}*/

.toolkit-container .accordions .p-accordion-header {
    transition: all	0.3s ease;
}

.toolkit-container .accordions .p-accordion-header.p-highlight {
    background-color: #4A4A4A !important;
}

.toolkit-container .accordions .p-accordion-header-link {
    display: flex;
    flex-direction: row;
    gap: 12px;
    /*padding: 0 !important;*/
    background-color: transparent !important;
}

.toolkit-container .accordions .p-accordion-header .header {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 1.5rem;
}

.toolkit-container .accordions .p-accordion-header.p-highlight .p-accordion-header-text {
    color: #FDC82F !important;
}

.toolkit-container .query{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.toolkit-container .query .switch{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.toolkit-container .query .warning-graph-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.toolkit-container .query .warning{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;
    padding: 12px;

    background-color: rgba(253, 200, 47, 0.4);
}

.toolkit-container .query .warning-filter-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.toolkit-container .query .warning-filter-container .filter-query{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.toolkit-container .query .warning-filter-container .filter-query .policy-level,
.toolkit-container .query .warning-filter-container .filter-query .sector{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.toolkit-container .query .warning-filter-container .filter-query .policy-level p,
.toolkit-container .query .warning-filter-container .filter-query .sector p {
    font-weight: 500;
}

.toolkit-container  .p-paginator{
    justify-content: center;
}

/*.p-tooltip-text {*/
/*   display: none;*/
/*}*/

.p-tooltip .p-tooltip-text{
    background: #fff !important;
    border: 1px solid #a2a2a2;
    border-radius: 4px;
    width: 90%;
    /*height: 200px;*/
    /*width: 500px;*/
}

/*.tooltip-box {*/
/*    width: 90% !important;*/
/*}*/

.treemap {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
}

.treemap .legend-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.treemap .legend-container .legends{
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
}

.treemap .legend-container .legends .legend-box{
    width: 20px;
    height: 20px;
    background: black;
    justify-self: center;
    border-radius: 4px;
}

.dialog-content .tool .sub-page .step {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 20px;
}

.dialog-content .tool .sub-page .step .header {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
	background: #f3f3f3 !important;
}
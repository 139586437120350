.information-page .body-container .where .map-component {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.display-general-livestock-container{
	background: rgba(0, 0, 0, 0.72);
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 100%;
	height: calc(100vh);
	gap: 250px;
}

.livestock-btn-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.livestock-btn-container .livestock-btn{
	background: transparent !important;
}

.livestock-btn-container i {
	color: #FDC82FFF;
}

.display-general-livestock-container .livestock-mapping,
.display-general-livestock-container .general-mapping{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}

.display-general-livestock-container .livestock-mapping h3,
.display-general-livestock-container .general-mapping h3{
	color: #fff;
	font-weight: 600;
	font-size: 20px;
}

.display-general-livestock-container .livestock-mapping p,
.display-general-livestock-container .general-mapping p{
	color: #fff;
	font-size: 14px;
}

.display-general-livestock-container .livestock-mapping img,
.display-general-livestock-container .general-mapping img{
	width: 70px;
}

.hide-general-livestock-container{
	display: none;
}

.warning-switch-container{
	display: flex;
	flex-direction: row;
	gap: 10px;
}


.warning-switch-container .switch{
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
	align-items: center;
	padding-right: 10px;
}

.warning-switch-container .switch img {
	width: 50px;

}

.map-dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: 100%;
}

.map-dialog .sub-title p {
	font-size: 20px;
	font-weight: 600;
}

.map-dialog .bubble {
	display: flex;
	padding: 12px;
	color: white;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
}

.megapixel-details .line-charts {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.map-dialog .impact-pathways {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.map-dialog .chart-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 33%;
	gap: 12px;
	padding: 12px;
}
.map-dialog .chart-container p {
	font-weight: 600;
	font-size: 18px;
}

.map-dialog .chart-container .info {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 12px;
	position: relative;
	top: -20px;
}

.map-dialog .chart-container .info p {
	font-size: 15px;
	font-weight: 500;
}

.map-dialog .chart-container .info i {
	cursor: pointer;
}

/*.variable-descriptions a:link {*/
/*	color: #2d2d2d;*/
/*	background-color: transparent;*/
/*	text-decoration: none;*/
/*}*/
.variable-descriptions {
	padding: 24px;
}
.variable-descriptions a:link {
	color: #FDC82FFF;
	background-color: transparent;
	text-decoration: none;
}
.variable-descriptions a:visited {
	color: #FDC82FFF;
	background-color: transparent;
	text-decoration: none;
}



.menu {
    display: flex;
    width: 100%;
    background-color: #2d2d2d;
    align-items: stretch;
    justify-content: space-between;
}

.menu img {
    /*margin-top: -25px;*/
    cursor: pointer;
    padding: 10px;
    height: 60px;
    object-fit: contain;
}

.menu .buttons{
    display: flex;
    width: 85%;
    justify-content: flex-end;
    align-items: center;
}
.menu .buttons p{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    padding: 10px 30px;
    height: 100%;
    font-weight: 500;
    color: #fff;
    transition: color 0.3s, background-color 0.3s;
}
.menu .buttons p:hover{
    transition: color 0.3s, background-color 0.3s;
    color: #2d2d2d !important;
    background-color: #FDC82FFF;
}

.menu .buttons .p-ripple{
    cursor: pointer;
    height: 100%;
}
.menu .buttons .p-ink{
    background: #2d2d2d30;
}

.menu .buttons .menu-option {
    position: relative;
    height: 100%;
}

/*.menu .buttons .sub-menu {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    background-color: white;*/
/*    box-shadow: -1px 3px 8px -3px gray;*/


/*    visibility: hidden;*/
/*    opacity: 0;*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left: auto;*/
/*    z-index: 1100;*/
/*    transition: all 0.3s ease 0s, visibility 0s linear  0.3s, z-index 0s linear 0.01s;*/
/*}*/

/*.menu .buttons .menu-option:focus .sub-menu,*/
/*.menu .buttons .menu-option:focus-within .sub-menu,*/
/*.menu .buttons .menu-option:hover .sub-menu {*/
/*    !*visibility: visible;*!*/
/*    opacity: 1;*/
/*    z-index: 1100;*/
/*    transition-delay: 0s,0s,0.3s;*/
/*}*/

/*.menu .buttons .sub-menu .divider {*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    background-color: lightgray;*/
/*    margin: 0;*/
/*}*/
/*.menu .buttons .sub-menu p {*/
/*    font-size: 14px;*/
/*    justify-content: flex-start;*/
/*}*/

.menu .bar{
    display: flex;
    height: 2px;
    /*background-color: #2d2d2d;*/
    width: 100px;
    position: absolute;
    transition: position 0.6s
}

.menu .divider {
    /*height: 40px;*/
    width: 1px;
    background-color: lightgray;
    margin: 20px 0;
}

.menu .settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    padding: 12px;
    color: #fff;

    position: relative;
}

.menu .settings i.gear {
    color: #fff;
}

.menu .settings i {
    font-size: 24px;
    transition: all 0.3s ease-in;
    color: #ffffff;
}

.menu .settings .options {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 24px;
    padding: 24px;
    flex-direction: column;
    background-color: white;
    box-shadow: -1px 3px 8px -3px gray;

    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 999;
    transition: all 0.3s ease 0s, visibility 0s linear  0.3s, z-index 0s linear 0.01s;
}

.menu .settings .options .p-dropdown-label {
    padding: 8px;

}

/*.p-dropdown-panel {*/
/*    z-index: 1101 !important;*/
/*}*/

.menu .settings:focus .options,
.menu .settings:focus-within .options,
.menu .settings:hover .options {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
    transition-delay: 0s,0s,0.3s;
}

.menu .settings:focus .fa-gear,
.menu .settings:focus-within .fa-gear,
.menu .settings:hover .fa-gear {
    transform: rotate(180deg);
}

.menu .settings .options .darkmode {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}
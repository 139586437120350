.social-media-analysis-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 25px;
    padding: 1rem;
    border-radius: 4px;
}

.social-media-analysis-container h2{
    font-size: 30px;
    color: #FDC82F;
}

.social-media-analysis-container .info-dropdown-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social-media-analysis-container .charts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-media-analysis-container .charts .selections {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 100px;
}

.social-media-analysis-container .charts .selections .variables {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.social-media-analysis-container .charts .buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.social-media-analysis-container .charts .buttons .p-button {
    border-radius: 20px;
}

.social-media-analysis-container .charts .chart {
    display: flex;
    align-items: center;
    width: 100%;
}

.social-media-analysis-container .charts .chart p{
    width: 33%;
}
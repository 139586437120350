.information-page .expanded-menu  {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: space-between;*/
    width: 400px;
    min-width: 400px;
    min-height: calc(100vh - 100px);
    /*overflow-y: scroll;*/
    transition: all 0.3s ease, vertical-align 0s;
    position: relative;
    background: #F7F7F7;
    padding: 20px;
    gap: 32px;
}

.information-page .expanded-menu .header {
    display: flex;
    /*width: 100%;*/
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.information-page .expanded-menu .header p {
    color: #FDC82F;
    font-size: 32px;
    font-weight: 600;
}

.information-page .expanded-menu::-webkit-scrollbar {
    display: none;
}
.information-page .expanded-menu #toggle.p-button {
    background: transparent;
    color: #2D2D2D;
    border-radius: 0;
    padding: 0;
    /*width: unset;*/
}

.information-page .expanded-menu .p-tabview {
    width: 100%;
    height: 100%;
}

.information-page .expanded-menu .p-tabview-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    border: none;
    background: transparent;
}

.information-page .expanded-menu .p-tabview-ink-bar {
    /*display: none !important;*/
    background-color: #FDC82F !important;
}

.information-page .expanded-menu .p-tabview-panels {
    background: rgb(247, 247, 247);
    padding: 40px 0;
}

.information-page .expanded-menu .tab-header .p-ripple {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px;
}
.information-page .expanded-menu .tab-header img, .information-page .expanded-menu .tab-header svg {
    width: 40px;
    height: 40px;
}

.information-page .expanded-menu .tab-header path, .information-page .expanded-menu .tab-header p {
    transition: all 0.3s;
}

.information-page .expanded-menu .tab-header .p-ripple:hover path, .information-page .expanded-menu .tab-header .p-ripple:hover p {
    fill: #FDC82F !important;
    color: #FDC82F !important;
}

.learn-more.p-dialog .p-dialog-header .p-dialog-title{
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
}

.learn-more.p-dialog .learn-more-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 32px;
}

.learn-more.p-dialog .learn-more-content .row {
    width: 100%;
}

.learn-more.p-dialog .learn-more-content .header .title {
    font-size: 24px;
    font-weight: 500;
}

.learn-more.p-dialog .learn-more-content .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 32px;
}

.learn-more.p-dialog .learn-more-content .p-column-title {
    color: #427730;
    font-weight: bold;
    font-size: 16px;
}

.learn-more.p-dialog .learn-more-content .p-rowgroup-header td{
    border-bottom: 1px solid #427730;
}

.learn-more.p-dialog .learn-more-content .row-group-header {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #427730;
    font-weight: bold;
    font-size: 16px;
}

.learn-more.p-dialog .learn-more-content .p-datatable {
    width: 100%;
}

.learn-more.p-dialog .learn-more-content .p-datatable-tbody td {
    width: 0%;
}
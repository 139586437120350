.team-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* height: fit-content; */
    align-items: flex-start;
    position: relative;
}

.team-container .team-content {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
    width: 100%;
    margin-top: 40px;
    gap: 50px;
    padding: 0 80px;
}

.team-container .team-content.management, .team-content.collaborators{
    background: #F8F8F8;
    padding: 50px 80px;
}

.team-container .team-content h2{
    color: #FDC82FFF;
    font-size: 28px;
}

.team-container .team-content .cards-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:  100%;
    gap: 30px;
}
.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
}

.p-grid {
    margin: 0;
}

.p-component, .p-link {
    font-family: Montserrat;
}

h1,h2,h3,h4,h5 {
    font-family: Lato;
    margin: 0;
}

p, span, div {
    font-family: Montserrat;
    margin: 0;
}

a {
    text-decoration: unset;
    overflow-wrap: break-word;
    max-width: 100%;
}

.leaflet-control-attribution a svg {
    display: none;
}

.open-sans {
    font-family: "Open Sans";
}
.p-button-label {
    font-weight: 400;
}

.p-datatable-tbody td {
    font-family: "Open Sans";
}

.p-column-title {
    font-family: "Open Sans";
}

.tab-header {
    line-height: 160%;
}

.p-dropdown-item, .p-dropdown-label.p-placeholder, .p-dropdown-label.p-inputtext {
    font-family: "Open Sans";
}

.p-card {
    background-color: #f7f7f7 !important;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15) !important;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15) !important;
}

.gleap-frame-container {
    max-height: 650px !important;
    height: 80vh !important;
}

.bb-feedback-button{
    display: flex;
    visibility: visible;
}

.bb-feedback-button-classic {
    display: flex;
    visibility: visible;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        color: transparent;
        background: transparent;
    }
    100% {
        opacity: 1;
    }
}

.policy-coherence-container .p-tabview .p-tabview-panels{
    background: #F7F7F7;
}

.policy-coherence-container .p-tabview .p-tabview-nav {
    background: #F7F7F7;
    padding: 5px;
    gap: 30px;
}

/*.policy-coherence-container .p-tabview .p-tabview-nav li {*/
/*    marging: 30px;*/
/*}*/

.policy-coherence-container .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    background: #F7F7F7;
}

.policy-coherence-container .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    background: #F7F7F7
}

.policy-coherence-container .p-tabview .p-tabview-nav .p-tabview-ink-bar{
    background: #fdc82f;
}

.policy-coherence-container .p-tabview .p-tabview-nav{
    border: none;
    justify-content: center;
}

.policy-coherence-container .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
    border: none;
    color: #000;
}

.policy-coherence-container .tabs {
    padding: 5px;
}

.policy-coherence-container .tabs path, .tabs p{
    transition: all 0.3s;
    text-align: center;
}

.policy-coherence-container .tabs:hover path, .tabs:hover p {
    fill: #FDC82F !important;
    color: #FDC82F !important;
    cursor: pointer;
}

.policy-coherence-container .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover{
    background: transparent !important;
}

.policy-coherence-container .p-tabview .p-tabview-panels h3{
    padding-bottom: 20px;
}

.policy-coherence-container .panel-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.policy-coherence-container .panel-container ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0;
}
.approach-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 160px);
	width: 100%;
	padding: 20px;
}

.approach-container .approach {
	display: flex;
	width: 100%;
	height: 90%;
}
.approach-container .approach svg{
	width: 100%;
	height: 100%;
}
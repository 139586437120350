.information-page .expanded-menu .tab-content {
    display: flex;
    position: relative;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

.information-page .expanded-menu .tab-content .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 10px;
}


.information-page .expanded-menu .tab-content .tab .title {
    width: 100%;
    font-size: 20px;
}

.information-page .expanded-menu .tab-content .tab .title span {
    color: #FDC82F;
    font-weight: 700;
}
.country-na-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: justify;
    width: 33%;
}

.country-na-text-container p, .country-na-text-container li {
    font-family: "Open Sans"
}
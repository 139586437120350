.cluster-definition .conflict {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	gap: 12px;
	width: 100%;
}

.cluster-definition .conflict .charts {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-evenly;
}

.cluster-definition .conflict .chart-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
}
.cluster-definition .conflict .chart-container p {
	font-weight: 600;
	font-size: 18px;
}

.cluster-definition .conflict .chart-container .info {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 12px;
	position: relative;
	top: -20px;
}

.cluster-definition .conflict .chart-container .info p {
	font-size: 15px;
	font-weight: 500;
}
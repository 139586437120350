.global-tools-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(10, 10, 10, 0.66), rgba(0, 0, 0, 0.63)), url("../../assets/images/GlobalTools/global.jpg");
    background-position: 65%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
    gap: 150px;
    padding: 20px 60px 50px;
}

.global-tools-page h1{
    color: #fff;
    font-size: 64px;
    font-family: Montserrat;
    text-transform: capitalize;
}

.global-tools-page .global-tools-content  {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    /*width: 100%;*/
    min-width: 600px;
    gap: 60px;
}

.global-tools-page .global-tools-content .button-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.global-tools-page .global-tools-content::-webkit-scrollbar {
    display: none;
}

.global-tools-page .global-tools-content #toggle.p-button {
    /*display: flex;*/
    /*align-items: flex-start;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 0px;*/
    background: #f7f7f7;
    color: #2D2D2D;
    border-radius: 0;
    /*padding: 10px;*/
}

.global-tools-page .global-tools-content .dropdown-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

.global-tools-page .global-tools-content .dropdown-container p {
    font-weight: 600;
}

.global-tools-page .global-tools-content .security-dialogues-card{
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

.global-tools-page .global-tools-content .security-dialogues-card .p-button {
    padding: 0;
    background: transparent;
    color: #427730;
}

.global-tools-page .global-tools-content .security-dialogues-card .p-button-label {
    font-weight: 600;
}

.global-tools-page .global-tools-content .security-dialogues-card .p-button:hover, .security-dialogues-card .p-button:focus {
    background-color: transparent;
    color: #427730;
}

.global-tools-page .global-tools-content .security-dialogues-card .p-card-title{
    font-family: Montserrat;
    font-size: 20px;
    color: #000;
}

.global-tools-page .global-tools-content .dialogues-card-read-more{
    background-color: transparent;
    color: #427730;
    font-weight: bold;
    cursor: pointer;
}

.dialog .p-dialog-header{
    padding: 0 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon{
    /*width: 17px !important;*/
}

.p-dialog .p-dialog-content{
    padding: 10px 40px 40px !important;
}

.security-dialogues-card .cl-dialogue-text::after{
    content: ".";
}

/*.dialog{*/
/*    width: 43%;*/
/*    height: 58%;*/
/*}*/

/*.dialog .p-dialog-title{*/
/*    font-family: Montserrat;*/
/*    font-size: 36px !important;*/
/*    font-weight: bold !important;*/
/*    color: #FDC82F;*/
/*}*/

.dialog-tool-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}

.dialog-tool-container h3{
    font-family: Montserrat;
    font-size: 36px !important;
    font-weight: bold !important;
    color: #FDC82F;
}

.dialog-tool-container .hyperlink-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dialog-tool-container a i {
    font-size: 20px;
}

.dialog-img-container{
    display: flex;
    justifyContent: center;
}
.dialog-img-container img {
    width: 100%;
    /*height: 100%;*/
    object-fit: cover;
}
.open-tool-button{
    color: #FDC82FFF;
    background: #2D2D2D;
    border: 0 none;
    padding: 0.714rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
    margin-bottom: 40px;
    width: fit-content;
}

.dialog-info-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.dialog-info-container p{
    line-height: 22px;
}

.open-tool-button:hover{
    background: #FDC82FFF;
    color: #2D2D2D;
    cursor: pointer;
}

.home .expanded-menu .twitter-card{
    width: 100%;
    display: flex;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s, z-index 0s;
}

.home .twitter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0 0;
    height: 100%;
    width: 380px;

    /*box-shadow: 0px 0px 17px -4px #c2c2c2 !important;*/
    overflow: hidden;
    background-color: #f7f7f7;
}

.home .twitter iframe {
    padding: 0px 20px 0 20px;
}

.home .twitter .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    padding: 0 20px;
}

.home .expanded-menu .twitter-card .twitter .header i {
    color: #7dd0ff;
}

.home .map-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #171717;
}



/* media queries */
.dialog{
    width: 80%;
    height: 90%;
}

/*@media screen and (min-width: 1080px){*/
/*    .dialog{*/
/*        width: 80%;*/
/*        height: 90%;*/
/*    }*/
/*}*/
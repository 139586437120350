.voices-field-content{
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
}

.voices-field-content .map-and-info {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 30px;
	text-align: justify;
}

.voices-field-content .map-and-info .p-tabview {
	height: 500px;
	width: 50%;
}

.voices-field-content .map-and-info .p-tabview-panels {
	overflow-y: scroll;
}

.voices-field-content .map-and-info .p-tabview {
	width: 100%;
	height: 100%;
}

.voices-field-content .map-and-info .p-tabview-nav {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	transition: all 0.3s ease;
	border: none;
	background: transparent;
}

.voices-field-content .map-and-info .p-tabview-ink-bar {
	/*display: none !important;*/
	background-color: #FDC82F !important;
}

.voices-field-content .map-and-info .p-tabview-panels {
	background: rgb(247, 247, 247);
}

.voices-field-content .map-and-info .tab-header .p-ripple {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 4px;
}
.voices-field-content .map-and-info .tab-header img, .voices-field-content .map-and-info .tab-header svg {
	width: 40px;
	height: 40px;
}

.voices-field-content .map-and-info .tab-header path, .voices-field-content .map-and-info .tab-header p {
	transition: all 0.3s;
}

.voices-field-content .map-and-info .tab-header .p-ripple:hover path, .voices-field-content .map-and-info .tab-header .p-ripple:hover p {
	fill: #FDC82F !important;
	color: #FDC82F !important;
}


.voices-field-content .map-and-info .accordion-section {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: flex-start;
	height: 500px;
	width: 100%;
}

.voices-field-content .map-and-info .accordion-section .text {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-bottom: 20px;
}

.voices-field-content .map-and-info .p-accordion {
	width: 100%;
}

/*.voices-field-content .map-and-info .p-accordion-content {*/
/*	height: 350px;*/
/*	overflow-y: scroll;*/
/*}*/

.voices-field-content .map-and-info .p-accordion-header {
	transition: all	0.3s ease;
}

.voices-field-content .map-and-info .p-accordion-header.p-highlight {
	background-color: #4A4A4A !important;
}


.voices-field-content .map-and-info .p-accordion-header-link {
	display: flex;
	flex-direction: row;
	gap: 12px;
	padding: 0 !important;
	background-color: transparent !important;
}

.voices-field-content .map-and-info .p-accordion-header .header {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	padding: 1.5rem;
}

.voices-field-content .map-and-info .p-accordion-header.p-highlight .header {
	color: #FDC82F;
	padding: 1.5rem;
}
.resources-container .resources-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 56px;
	gap: 48px;
}

.resources-container .resources-row .main-heading {
	position: absolute;
	color: #FDC82F;
	font-size: 32px;
	font-weight: 700;
	top: 24px;
	/*left: 15%;*/
}

.resources-container .resources-row .subtitle {
	font-size: 32px;
	font-weight: 700;
}

.resources-container .resources-row .column {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	width: 40%;
	padding-top: 24px;
}
.resources-container .resources-row .column .content {
	text-align: justify;
}
.resources-container .resources-row .column .p-button {
	width: fit-content;
}

.resources-container .resources-row .image {
	width: 30%
}

.resources-container .resources-row .background-image {
	position: absolute;
	width: 13%;
}

.row-layout-dialog .p-dialog-header {
	padding: 0 !important;
}
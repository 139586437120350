.approach-container .controls {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 20px;
}

.approach-container .controls .p-slider {
	width: 80%;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
}

.container .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    padding: 80px 0 100px;
}

.container .content .main-heading{
    font-size: 200px;
    color: #fdc82f;
}

.container .content p{
    font-size: 28px;
}
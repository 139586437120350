.resources-dialog-content {
	display: flex;
	flex-direction: column;
	padding: 24px;
	gap: 24px;
}

.resources-dialog-content .p-datatable, .resources-dialog-content .p-datatable-table,.resources-dialog-content .p-datatable, .resources-dialog-content .p-datatable-tbody, .resources-dialog-content tr {
	width: 100%;
}

.resources-dialog-content .p-datatable-tbody td {
	width: 0%;
	overflow-wrap: break-word;
}

.resources-dialog-content .p-column-title {
	color: #427730;
	font-weight: bold;
	font-size: 16px;
}

.resources-dialog-content .title {
	font-size: 32px;
	font-weight: 700;
	color: #FDC82F;
}
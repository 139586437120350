.information-page .body-container .content-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    gap: 20px;
    width: 400px;
}

.information-page .body-container .content-card.tool-what-card {
    background: #F7F7F7;
    padding: 20px;
    width: 500px;
}

.information-page .body-container .content-card .image-text-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.information-page .body-container .content-card .image-text-container h3, .information-page .body-container .content-card .image-text-container p {
    padding: 0 12px;
}

.information-page .body-container .content-card .image-text-container img {
    width: 100%;
    height: 267.54px;
}

.information-page .body-container .content-card h3{
    font-family: Montserrat;
    font-size: 20px;
    color: #FDC82F;
    margin-top: 10px;
}

.information-page .body-container .content-card h5{
    font-family: Montserrat;
    font-size: 14px;
    color: #000000;
}

.information-page .body-container .content-card .p-button{
    padding: 0 12px 12px;
    background: transparent;
    color: #427730;
}

.information-page .body-container .content-card .p-button.open-tool-button {
    color: #FDC82FFF;
    background: #2d2d2d;
    border: 0 none;
    padding: 0.714rem 1rem;
    margin: 0 12px 12px;
    font-size: 1rem;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
}

.information-page .body-container .content-card .p-button-label{
    font-weight: 600;
    text-transform: uppercase;

}

.country-page-dialog.p-dialog .p-dialog-header{
    padding: 0 !important;
}



/*.p-dialog .p-dialog-header{*/
/*    padding: 0 !important;*/
/*}*/



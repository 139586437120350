.menu .buttons .sub-menu {
    display: flex;
    flex-direction: column;
    background-color: #2d2d2d !important;
    box-shadow: -1px 3px 8px -3px gray;


    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: auto;
    z-index: 1100;
    transition: all 0.3s ease 0s, visibility 0s linear  0.3s, z-index 0s linear 0.01s;
}

.menu .buttons .menu-option:focus .sub-menu,
.menu .buttons .menu-option:focus-within .sub-menu,
.menu .buttons .menu-option:hover .sub-menu {
    visibility: visible;
    opacity: 1;
    z-index: 1100;
    transition-delay: 0s,0s,0.3s;
}

.menu .buttons .sub-menu .divider {
    width: 100%;
    height: 1px;
    background-color: lightgray;
    margin: 0;
}
.menu .buttons .sub-menu p {
    font-size: 14px;
    justify-content: center;
    padding: 20px 5px !important;
}

.menu .buttons .sub-menu p {
    font-size: 14px;
    justify-content: center;
    padding: 20px 5px !important;
}

.menu .buttons .sub-menu p:hover{
    transition: color 0.3s, background-color 0.3s;
    color: #FDC82FFF !important;
    background-color: #2d2d2d;
}

.menu .buttons .sub-menu div.survey p {
    font-size: 14px;
    justify-content: center;
    padding: 20px 5px !important;
}

.menu .buttons .sub-menu div.survey p:hover{
    transition: color 0.3s, background-color 0.3s;
    color: #FDC82FFF !important;
    background-color: #2d2d2d;
}

.menu .bar{
    display: flex;
    height: 2px;
    /*background-color: #2d2d2d;*/
    width: 100px;
    position: absolute;
    transition: position 0.6s
}

.menu .divider {
    /*height: 40px;*/
    width: 1px;
    background-color: lightgray;
    margin: 20px 0;
}
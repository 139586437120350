.body-container .context .no-variable {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 24px;
	gap: 24px;
}
.body-container .context .no-variable .title {
	font-size: 32px;
	font-weight: 600;
}
.body-container .context .no-variable .warning {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	padding: 12px;

	background-color: rgba(253, 200, 47, 0.4);
}
/* background image  class styles */

.bg-image-landing{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    background-image: url("../../assets/images/LandingPage/landing.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
    padding: 50px;
    -webkit-animation: imgFade 5s;
    animation: imgFade 5s;

}

@-webkit-keyframes imgFade
{
    0% {
        background: rgba(0, 0, 0, 0.7);
    }
    100% {
        background: rgba(0, 0, 0, 0.7);
    }
}

.bg-image-landing iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 178vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.bg-image-landing .icons{
    display: flex;
    flex-direction: row;
    gap: 30px;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.bg-image-landing .title-subtitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding-top: 10%;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.bg-image-landing .title-subtitle h1{
    color: #FDC82F;
    font-size: 64px;
    font-family: Montserrat;
}

.bg-image-landing .subtitle{
    text-align: center;
    color: white;
    font-size: 32px;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.bg-image-landing .landing-button{
    color: #FDC82FFF;
    background: rgba(253, 200, 47, 0.2);
    border: 0 none;
    padding: 0.714rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    margin-bottom: 20px;
    z-index: 1;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.bg-image-landing .landing-button:hover{
    background: #FDC82FFF;
    color: #2D2D2D;
    cursor: pointer;
}

.landing-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
    /*background: transparent;*/

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
            );

    min-height: calc(100vh - 160px);
    position: relative;
    overflow: hidden;
    padding: 50px;
}

.landing-page iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 178vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.landing-page .icons{
    display: flex;
    flex-direction: row;
    gap: 30px;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.landing-page .title-subtitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding-top: 10%;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.landing-page .title-subtitle h1{
    color: #FDC82F;
    font-size: 64px;
    font-family: Montserrat;
    position: relative;
}

.landing-page .subtitle{
    text-align: center;
    color: white;
    font-size: 32px;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.landing-page .landing-button{
    color: #FDC82FFF;
    background: rgba(253, 200, 47, 0.2);
    border: 0 none;
    padding: 0.714rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    margin-bottom: 20px;
    z-index: 1;

    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.landing-page .landing-button:hover{
    background: #FDC82FFF;
    color: #2D2D2D;
    cursor: pointer;
}




